import axios from 'axios'
import { API_URL } from 'core/config'
import { useQuery } from 'react-query'

// reference to old category ranges
// const upperLimits = [
//     '5',
//     '19',
//     '29',
//     '69',
//     '79',
//     '99',
//     '117',
//     '139',
//     '199',
//     '209',
//     '219',
//     '229'
// ]

function getFundCategory(FundGroupOrder: number, fundGroupId: number): string {
    if (fundGroupId === 76) {
        return 'Canada Life Funds'
    } else {
        let category = ''
        if (FundGroupOrder > 0) {
            category = 'Irish Life Funds'
        }
        if (FundGroupOrder > 117) {
            category = 'Setanta Funds'
        }
        if (FundGroupOrder > 140) {
            category = 'External Fund Manager'
        }
        if (FundGroupOrder > 199) {
            category = 'Irish Life Funds'
        }
        return category
    }
}

function addCategoryToFunds(data: Record<string, string>[], fundGroupId: number): Record<string, string>[] {
    return data.map((item) => ({ ...item, Category: getFundCategory(+item.FundGroupOrder, fundGroupId) }))
}

async function getFundsData(fundGroupId?: number) {
    if (fundGroupId) {
        return axios.post(`${API_URL}/myonlineservices/FundApi/RetrieveFundsPerformanceIL?fundGroupId=${fundGroupId}`)
            .then(({ data }) => addCategoryToFunds(data, fundGroupId))
    }

    const [group73, group76] = await Promise.all([
        axios.post(`${API_URL}/myonlineservices/FundApi/RetrieveFundsPerformanceIL?fundGroupId=73`).then(({ data }) => addCategoryToFunds(data, 73)),
        axios.post(`${API_URL}/myonlineservices/FundApi/RetrieveFundsPerformanceIL?fundGroupId=76`).then(({ data }) => addCategoryToFunds(data, 76))
    ])

    return [...group73, ...group76]
}

export function useFundsQuery(fundGroupId?: number) {
    return useQuery<Record<string, string>[], Error>(['funds', fundGroupId], () => getFundsData(fundGroupId))
}
