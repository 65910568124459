import React from 'react'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { getLayoutData } from 'common/utils/getLayoutData'
import { FundCentrePageProps } from 'common/components/templates/FundDetails'
import { FundCentre } from 'common/components/templates/FundCentre'

// 1. `${API_URL}/myonlineservices/FundApi/RetrieveFundPerformanceCharts?fundId=` +
// fundId, //SINGLE FUND INFO AJAX CALL

// 2. `${API_URL}/myonlineservices/FundApi/RetrieveFundComparisonChart?fundIds=` +
// fundString.slice(0, -1), //COMPARE AJAX CALL CHANGE TO IRISHLIFE.IE WHEN GOING LIVE

// 3. `${API_URL}/myonlineservices/FundApi/RetrieveFundsPerformanceIL?fundGroupId=` +
// fundGroupMain, //OVERALL AJAX CALL CHANGE TO IRISHLIFE.IE WHEN GOING LIVE

// 4. `${API_URL}/myonlineservices/FundApi/RetrieveFundGroups?applicationId=` +
// productGroupsId,

export const FundCentrePage: React.FC<FundCentrePageProps> = ({ pageContext }) => {
    return <FundCentre {...getFundCentreData(pageContext)} />
}

export default FundCentrePage

export const getFundCentreData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    // codename: page,
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    },
    benefits: {
        benefits: (data.threecards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title
    },
    featured: {
        title: data.featured__title,
        text: data.featured__text,
        callToAction: {
            icon: data.featured__call_to_action_icon,
            text: data.featured__call_to_action_text,
            url: data.featured__call_to_action_url,
        }
    },
    productList: {
        heading: data.heading,
        title: data.title,
        text: '',
        products: (data.products || []).map((product: any) => ({
            icon: product.icon,
            subtitle: product.productlist__subtitle,
            title: product.title,
            text: product.text,
            callToAction: {
                label: product.card_cta_0396ba7__cta_label,
                variant: product.card_cta_0396ba7__cta_variant,
                url: product.card_cta_0396ba7__cta_url
            }
        }))
    },
    information: {
        title: data.title_information,
        text: (data.paragraphs || []).map(
            (paragraph: { text: string }) => paragraph.text
        )
    },
    extraDownloads: {
        features: (data.extra_downloads || []).map((extra_download: any) => ({
            title: extra_download.title,
            fileUrl: extra_download.file_url
        }))
    }
})
