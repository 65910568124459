import { FundsTable } from 'common/components/organisms/FundsTable'
import React from 'react'
import { Box, Stack } from '@chakra-ui/react'
import { Spinner } from '@irishlife/ilgroupdesignsystem.atoms.spinner'
import { FilterFunds } from './FilterFunds'
import { useFundsQuery } from 'common/hooks/useFundsQuery'

type DataTableProps = {
    setSelectedFunds: Function;
    selectedFunds: string[];
    fundGroupId: number
}

type Fund = Record<string, string | number>

export function DataTable(props: DataTableProps) {
    const { setSelectedFunds, fundGroupId, selectedFunds } = props

    const { data, status } = useFundsQuery(fundGroupId)

    const [riskRatings, setRiskRatings] = React.useState<string[]>([])
    const [categories, setCategories] = React.useState<string[]>([])
    const [sfdrArticleRatings, setSfdrArticleRatings] = React.useState<string[]>([])

    const filteredData = React.useMemo(() => {
        if (!data) return []
        return filterData({ data, filters: { riskRatings, categories, sfdrArticleRatings } })
    }, [data, riskRatings, categories, sfdrArticleRatings])

    const onSelect = React.useCallback((selectedFundIds: string[]) => {
        setSelectedFunds(selectedFundIds)
    }, [setSelectedFunds])

    const onRiskRatingChange = React.useCallback((ratings: string[]) => {
        setRiskRatings(ratings)
    }, [setRiskRatings])

    const onCategoryChange = (managers: string[]) => {
        setCategories(managers)
    }

    const onSfdrArticleRatingsChange = (articleRatings: string[]) => {
        setSfdrArticleRatings(articleRatings)
    }

    const dataIsNotReady = status === "loading" || status === "error"

    if (dataIsNotReady) {
        return (
            <Stack height='100vh' pt={32} isInline width='full' justifyContent='center'>
                <Spinner size='lg' />
            </Stack>
        )
    }

    return (
        <Box w='full'>
            <FilterFunds
                selectedFunds={selectedFunds}
                data={data}
                riskRatings={riskRatings}
                sfdrArticleRatings={sfdrArticleRatings}
                categories={categories}
                onSfdrArticleRatingsChange={onSfdrArticleRatingsChange}
                onRiskRatingChange={onRiskRatingChange}
                onCategoryChange={onCategoryChange}
            />
            <FundsTable
                data={filteredData}
                defaultSelected={[]}
                onSelect={onSelect}
            />

        </Box>
    )
}

type FilterDataProps = {
    data: Fund[]
    filters: {
        riskRatings: string[],
        categories: string[],
        sfdrArticleRatings: string[]
    }
}

function filterData(props: FilterDataProps) {
    const { data, filters: { riskRatings, categories, sfdrArticleRatings } } = props

    let results = data

    if (riskRatings.length > 0 && categories.length > 0 && sfdrArticleRatings.length > 0) {
        return results.filter(item => {
            const hasRating = riskRatings.some(riskRating => riskRating === item.FundRiskRating)
            const hastManager = categories.some(fundManager => fundManager === item.Category)
            const hasSfdrArticleRatings = sfdrArticleRatings.some(articleRating => articleRating === item.SfdrArticleRating)
            return hasRating && hastManager && hasSfdrArticleRatings
        })
    }
    if (riskRatings.length > 0 && categories.length === 0 && sfdrArticleRatings.length === 0) {
        return results.filter(item => {
            return riskRatings.some(riskRating => riskRating === item.FundRiskRating)
        })
    }
    if (riskRatings.length === 0 && categories.length > 0 && sfdrArticleRatings.length === 0) {
        return results.filter(item => {
            return categories.some(category => category === item.Category)
        })
    }
    if (riskRatings.length === 0 && categories.length === 0 && sfdrArticleRatings.length > 0) {
        return results.filter(item => {
            return sfdrArticleRatings.some(sfdrArticleRating => sfdrArticleRating === item.SfdrArticleRating)
        })
    }

    return results
}
