import { Box, HStack, Stack } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Accordion } from '@irishlife/ilgroupdesignsystem.molecules.accordion'
import { Choice } from '@irishlife/ilgroupdesignsystem.molecules.choice'
import { Modal } from '@irishlife/ilgroupdesignsystem.molecules.modal'
import { Search } from '@irishlife/ilgroupdesignsystem.molecules.search'
import { navigate, useLocation } from '@reach/router'
import React from 'react'
import { CompareFundsContainer } from './CompareFundsContainer'

type Fund = Record<string, string | number>

type FilterFundsProps = {
    data: Fund[] | undefined
    riskRatings: string[]
    categories: string[]
    sfdrArticleRatings: string[]
    selectedFunds: string[]
    onRiskRatingChange: (value: string[]) => void
    onCategoryChange: (value: string[]) => void
    onSfdrArticleRatingsChange: (value: string[]) => void
}

type Option = {
    id: number | string
    value: number | string
    label: number | string
}

export function FilterFunds(props: FilterFundsProps) {
    const { pathname } = useLocation()
    const isIrishLife = pathname.includes('fund-prices-and-performance')
    const entityPath = isIrishLife ? 'irish-life' : 'canada-life'

    const {
        data,
        riskRatings = [],
        categories = [],
        sfdrArticleRatings = [],
        onRiskRatingChange,
        onCategoryChange,
        onSfdrArticleRatingsChange,
        selectedFunds
    } = props
    const [isOpen, setIsOpen] = React.useState(false)
    const [ratingsSelected, setRatingsSelected] = React.useState(riskRatings)
    const [categoriesSelected, setCategoriesSelected] = React.useState(
        categories
    )
    const [sfdrArticleRatingsSelected, setSfdrArticleRatingsSelected] = React.useState(sfdrArticleRatings)

    const { onClose, onOpen } = React.useMemo(
        () => ({
            onClose: () => setIsOpen(false),
            onOpen: () => setIsOpen(true)
        }),
        []
    )

    const onSave = React.useCallback(() => {
        onRiskRatingChange(ratingsSelected)
        onCategoryChange(categoriesSelected)
        onSfdrArticleRatingsChange(sfdrArticleRatingsSelected)
        onClose()
    }, [
        ratingsSelected,
        categoriesSelected,
        sfdrArticleRatingsSelected,
        onClose,
        onRiskRatingChange,
        onCategoryChange,
        onSfdrArticleRatingsChange
    ])

    const onClear = React.useCallback(() => {
        onRiskRatingChange([])
        onCategoryChange([])
        onSfdrArticleRatingsChange([])
        onClose()
    }, [onClose, onRiskRatingChange, onCategoryChange, onSfdrArticleRatingsChange])

    const count = riskRatings.length + categories.length + sfdrArticleRatings.length

    const openIndexList = React.useMemo(() => {
        const indexes = []
        if (ratingsSelected.length > 0) indexes.push(0)
        if (categoriesSelected.length > 0) indexes.push(1)
        if(sfdrArticleRatings.length > 0) indexes.push(2)
        return indexes
    }, [ratingsSelected, categoriesSelected, sfdrArticleRatings])

    const riskRatingOptions = React.useMemo(() => {
        if (!data) return []
        return getRiskRatingOptions(data)
    }, [data])

    const categoryOptions = React.useMemo(() => {
        if (!data) return []
        return getCategoryOptions(data)
    }, [data])

    const sfdrArticleRatingOptions = React.useMemo(() => {
        if(!data) return []
        return getSfdrArticleRatingOptions(data)
    }, [data])

    const fundNames = React.useMemo(() => {
        if (!data) return []
        return data.map(({ FundName, FundId }) => ({
            id: FundId,
            value: FundId,
            label: FundName
        }))
    }, [data])

    return (
        <>
        <Text variant='title-md' color='#717fe2' as='h2' onClick={() => {
            navigate(
                `https://www.irishlife.ie/sfdr`
            )
        }} mb='1em'>
            Sustainability related disclosures
        </Text>
        <Stack
            py={4}
            width='full'
            direction={['column', 'row']}
            alignItems='center'
            bg='#F7F8FB'
            px={4}
            spacing={4}
        >

            <FilterModal
                isOpen={isOpen}
                onClose={onClose}
                openIndexList={openIndexList}
                riskRatings={riskRatings}
                riskRatingOptions={riskRatingOptions}
                categories={categories}
                sdfrArticleRatings={sfdrArticleRatings}
                sfdrArticleRatingOptions={sfdrArticleRatingOptions}
                onSave={onSave}
                onClear={onClear}
                count={count}
                onOpen={onOpen}
                setRatingsSelected={setRatingsSelected}
                setCategoriesSelected={setCategoriesSelected}
                setSfdrArticleRatingsSelected={setSfdrArticleRatingsSelected}
                categoryOptions={categoryOptions}
            />
            <CompareFundsContainer selectedFunds={selectedFunds} />
            <HStack flex={1} justifyContent='flex-end' w='full'>
                <Box w={['full', '300px']}>
                    <Search
                        // @ts-ignore
                        onSelect={id =>
                            navigate(
                                `fund-details-${entityPath}-investments?id=${id}`
                            )
                        }
                        placeholder='Search funds'
                        variant='filled'
                        options={fundNames}
                    />
                </Box>
            </HStack>
        </Stack>
        </>
    )
}

type FilterModalProps = Record<string, any>
function FilterModal(props: FilterModalProps) {
    const {
        setRatingsSelected,
        categoryOptions,
        setCategoriesSelected,
        isOpen,
        onClose,
        sfdrArticleRatings,
        setSfdrArticleRatingsSelected,
        sfdrArticleRatingOptions,
        openIndexList,
        riskRatings,
        riskRatingOptions,
        categories,
        onSave,
        onClear,
        count,
        onOpen
    } = props
    return (
        <>
            <Button
                rightIcon={<CounterIcon count={count} />}
                leftIcon={<FilterIcon />}
                onClick={onOpen}
                variant='ghost'
                rounded='md'
            >
                Filter
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <Box py={4}>
                    <Box pb={8}>
                        <Text variant='title-sm'>Filter funds</Text>
                    </Box>
                    <Box>
                        <Accordion
                            defaultIndex={openIndexList}
                            items={[
                                {
                                    id: 1,
                                    header: 'Risk rating',
                                    children: (
                                        <Choice
                                            defaultSelected={riskRatings}
                                            options={riskRatingOptions}
                                            // @ts-ignore
                                            onChange={setRatingsSelected}
                                        />
                                    )
                                },
                                {
                                    id: 2,
                                    header: 'Category',
                                    children: (
                                        <Choice
                                            columns={[1, 2]}
                                            defaultSelected={categories}
                                            // @ts-ignore
                                            options={categoryOptions}
                                            // @ts-ignore
                                            onChange={setCategoriesSelected}
                                        />
                                    )
                                },
                                {
                                    id: 3,
                                    header: 'SFDR Category',
                                    children: (
                                        <Choice
                                        columns={[1,2]}
                                        defaultSelected={sfdrArticleRatings}
                                        //@ts-ignore
                                        options={sfdrArticleRatingOptions}
                                        // @ts-ignore
                                        onChange={setSfdrArticleRatingsSelected}
                                        />
                                    )
                                }
                            ]}
                        />
                    </Box>
                    <HStack pt={8} spacing={[2, 4]}>
                        <Button w='full' variant='ghost' onClick={onClose}>
                            Cancel
                        </Button>
                        <Button w='full' variant='vibrant' onClick={onSave}>
                            Apply
                        </Button>
                        <Button w='full' variant='vibrant' onClick={onClear}>
                            Clear filters
                        </Button>
                    </HStack>
                </Box>
            </Modal>
        </>
    )
}

function getRiskRatingOptions(data: Fund[]) {
    return data
        .reduce((acc, { FundRiskRating }) => {
            if (!FundRiskRating || acc.some(o => o.value === FundRiskRating))
                return acc
            acc.push({
                id: FundRiskRating,
                value: FundRiskRating,
                label: `IL${FundRiskRating}`
            })
            return acc
        }, [] as Option[])
        .sort((a, b) => +b.value - +a.value)
}

function getCategoryOptions(data: Fund[]) {
    return data.reduce((acc, { Category }) => {
        if (!Category || acc.some(o => o.value === Category)) return acc
        acc.push({ id: Category, value: Category, label: Category })
        return acc
    }, [] as Option[])
}

function getSfdrArticleRatingOptions(data: Fund[]) {
    return data.reduce((acc, { SfdrArticleRating }) => {
        if(!SfdrArticleRating || acc.some(o => o.value === SfdrArticleRating )) return acc
        acc.push({ id: SfdrArticleRating, value: SfdrArticleRating, label: `Article ${SfdrArticleRating} Funds`})
        return acc
    }, [] as Option[])
}

function FilterIcon() {
    return (
        <svg
            width='24'
            height='24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clip-rule='evenodd'
                d='M23.2 2.054a.751.751 0 0 0-.748-.8H1.5a.75.75 0 0 0-.748.8 11.254 11.254 0 0 0 8.972 10.22v9.875a.75.75 0 0 0 1.23.576l3-2.5a.748.748 0 0 0 .27-.576v-7.375A11.253 11.253 0 0 0 23.2 2.054Z'
                stroke='#717FE2'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}

function CounterIcon({ count }: { count: number }) {
    if (count === 0) return null
    return (
        <Box
            width='26px'
            height='22px'
            display='flex'
            alignItems='center'
            borderRadius='4px'
            justifyContent='center'
            bg='#5261AC'
            pb='1px'
        >
            <Text color='white' as='span'>
                {count}
            </Text>
        </Box>
    )
}
