import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { navigate } from '@reach/router'
import { HStack } from '@chakra-ui/react'
import { useFundCentreUrls } from 'common/hooks/useFundCentreUrls'

export function CompareFundsContainer(props: { selectedFunds: string[] }) {
    const { selectedFunds = [] } = props
    const { compareFundsUrl } = useFundCentreUrls()
    const isDisabled = selectedFunds.length < 2
    const textContent = getTextContent({ isDisabled, selectedFunds })

    const onCompareFunds = React.useCallback(() => {
        const ids = selectedFunds.join(',')
        navigate(`${compareFundsUrl}?compare=${ids}`)
    }, [selectedFunds, compareFundsUrl])

    return (
        <HStack justifyContent={['center', 'space-between']} spacing={5}>
            <Text
                color={['vibrant.500', 'vibrant.500']}
                variant={['body-md', 'body-lg']}
                fontWeight='medium'>
                {textContent}
            </Text>
            {!isDisabled && <Button isDisabled={isDisabled} onClick={onCompareFunds}>Compare funds</Button>}
        </HStack>
    )
}

function getTextContent({ isDisabled, selectedFunds }: { isDisabled: boolean, selectedFunds: string[] }) {
    if (isDisabled) return `Select two or more funds to compare`
    return `<b>${selectedFunds.length} Fund${selectedFunds.length > 1 ? 's' : ''}</b> Selected`
}
