import React from 'react'
import { useLocation } from '@reach/router'

function getFundCentreUrls({
    pathname,
    postfix
}: {
    pathname: string
    postfix: 'investments' | 'pensions'
}) {
    const prefix = '/' + postfix + '/'

    const fundDetailUrl = (() => {
        if (pathname.includes('canada-life')) {
            return prefix + `fund-details-canada-life-${postfix}`
        } else {
            return prefix + `fund-details-irish-life-${postfix}`
        }
    })()
    const fundListUrl = (() => {
        if (pathname.includes('canada-life')) {
            return prefix + `canada-life-${postfix}`
        } else {
            return prefix + `fund-prices-and-performance-${postfix}`
        }
    })()
    const compareFundsUrl = (() => {
        if (pathname.includes('canada-life')) {
            return prefix + `funds-comparison-canada-life-${postfix}`
        } else {
            return prefix + `funds-comparison-irish-life-${postfix}`
        }
    })()
    const fundNewsUrl = prefix + `fund-news-${postfix}`
    const mapsQuarterlyUrl = prefix + `fund-maps-quarterly-updates${postfix}`

    return {
        fundDetailUrl,
        fundListUrl,
        fundNewsUrl,
        mapsQuarterlyUrl,
        compareFundsUrl
    }
}

export function useFundCentreUrls() {
    const { pathname } = useLocation()
    const {
        fundDetailUrl,
        fundListUrl,
        fundNewsUrl,
        mapsQuarterlyUrl,
        compareFundsUrl
    } = React.useMemo(() => {
        const isInvestments = pathname.split('/')[1] === 'investments'
        return getFundCentreUrls({
            pathname,
            postfix: isInvestments ? 'investments' : 'pensions'
        })
    }, [pathname])

    return {
        fundDetailUrl,
        fundListUrl,
        fundNewsUrl,
        mapsQuarterlyUrl,
        compareFundsUrl
    }
}
