import { Box, Container, Stack } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { HtmlContent } from '@irishlife/ilgroupdesignsystem.atoms.html-content'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Alert } from '@irishlife/ilgroupdesignsystem.molecules.alert'
import { navigate } from '@reach/router'
import { AlertsProps } from 'common/components/organisms/Alerts'
import { InformationProps } from 'common/components/organisms/Information'
import { JumbotronProps as OldJumbotronProps } from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { useFundCentreUrls } from 'common/hooks/useFundCentreUrls'
import React from 'react'
import TagManager from 'react-gtm-module'
import { DataTable } from './components/DataTable'

type FundCentreProps = {
    jumbotron: OldJumbotronProps
    layout: LayoutProps
    alerts: AlertsProps
    information: InformationProps
}

export function FundCentre({
    layout,
    jumbotron,
    alerts,
    information
}: FundCentreProps) {
    const [selectedFunds, setSelectedFunds] = React.useState<string[]>([])
    const { fundListUrl } = useFundCentreUrls()
    const fundGroupId = fundListUrl.includes('canada-life') ? 76 : 73

    return (
        <Layout {...layout} box={{ bgcolor: 'white' }}>
            <Container maxW='6xl'>
                <Stack
                    pb={2}
                    py={[10, 16]}
                    pl={4}
                    pr={[4, 4, 4, 0]}
                    maxW={['full', 'full', '744px']}
                    width='full'
                    spacing={[3, 3, 6]}
                >
                    <Text variant='title-2xl'>{jumbotron.title}</Text>
                    {jumbotron.subtitle && (
                        <Text variant={['title-sm', 'title-sm', 'title-md']}>
                            {jumbotron.subtitle}
                        </Text>
                    )}
                    <HtmlContent
                        sx={{
                            maxW: '3xl',
                            '* > *': { fontSize: '14px !important' }
                        }}
                    >
                        {information.text}
                    </HtmlContent>
                </Stack>
            </Container>
            <Container maxW='6xl' px={0}>
                <DataTable
                    selectedFunds={selectedFunds}
                    setSelectedFunds={setSelectedFunds}
                    fundGroupId={fundGroupId}
                />
            </Container>
            <Container maxW='6xl'>
                <Box pt={10}>
                    <Button
                        onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'primaryButtonClick',
                                    clickText: 'Contact us',
                                    clickURL: '/contact-us',
                                    clickID: jumbotron.callToAction?.analyticsId
                                }
                            })
                            navigate('/contact-us')
                        }}
                    >
                        Contact us
                    </Button>
                </Box>
                <Stack maxW='xl' py={[10, 16]}>
                    {alerts.alerts.map(alert => (
                        <Alert
                            key={alert}
                            status='warning'
                            textProps={{
                                fontWeight: 'bold'
                            }}
                        >
                            {alert}
                        </Alert>
                    ))}
                </Stack>
            </Container>
        </Layout>
    )
}
